import { RadixNetwork } from '@radixdlt/radix-dapp-toolkit';


export let RADIX_GATEWAY_URL ='https://gateway.radix.live'; 
export let RADIX_NETWORK_ID: 1 | 2 = RadixNetwork.Mainnet;

export let DAPP_DEFINITION_ACCOUNT_ADDRESS = 'account_rdx12ydm2gvafp9j877mn9jrtj6knfsg8tdnhp0ugcyzrkrse6yh0me3y6';
export let NFT_PACKAGE_ADDRESS = 'package_rdx1p5ex6e49x93t4860l5d5ygueg4t9qtff53d4t343xx740lymr4whrw';
export let NFT_COMPONENT_ADDRESS = 'component_rdx1crefd0tw78w7z89wxpv650m7j22xcs5eced8rjvzfk3jqn0t5e22s3';

export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

export function shortenAddress(address: string | null): string {
	if (!address) {
		return '';
	}

	return address.substring(0, 7) + '...' + address.substring(address.length - 6);
}